<template>
  <b-section-scroll
    title="部门待办"
    @more="$router.push('/task/backlog')"
  >
    <div
      v-if="list.length === 0"
      class="g-empty"
    ></div>
    <div
      class="item"
      v-for="item in list"
      :key="item.id"
      @click="onNav(item)"
    >
      <div class="name">
        {{ item.name }}
      </div>
      <!-- <div>dept</div> -->
      <div class="info">
        <span>{{ item.user.department.name }}</span>
        <span>发布</span>
        <span>{{ item.created_at }}</span>
      </div>
    </div>
  </b-section-scroll>
</template>

<script>
import { getBacklogPage } from '@/api/backlog/index'

export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.fetchList()
  },
  computed: {
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  methods: {
    fetchList() {
      const params = {
        current_page: 1,
        current_count: 10,
        state: '进行中,已逾期',
      }
      getBacklogPage(this.deptId, params, 'dept').then(res => {
        this.list = res.data
      })
    },
    onNav(row) {
      this.$router.push({
        path: '/task/info',
        query: { id: row.agenda_id, type: 'backlog', backlogId: row.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  position: relative;
  padding: 15px;
  background: rgba($color: #dfe3e8, $alpha: 0.26);
  border-radius: 10px;

  + .item {
    margin-top: 15px;
  }

  &:hover {
    cursor: pointer;
    background: rgba($color: #dfe3e8, $alpha: 0.4);
  }

  .name {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #2b2d42;
  }

  .info {
    font-size: 12px;
    color: #747e8f;
  }
}
</style>
